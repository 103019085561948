import React from "react";
import "./index.css";

const Services = () => {
  return (
    <div className="service-cont">
      <div className="service-body">
        <section className="Services-wrapper">
          <p
            style={{
              fontSize: "5vw",
              fontFamily: "Poppins, sans-serif",
              fontWeight: "700",
            }}
          >
            Our Services
          </p>
          <p className="section-lead para">
            We partner with businesses of all sizes to identify and recruit top
            talent. Our services include candidate sourcing, screening, and
            placement, allowing companies to focus on what they do best.
          </p>
          <div className="services-grid">
            <div className="service service1">
              <i className="ti-bar-chart"></i>
              <h4 className="font-glacial">Turnkey Staffing</h4>
              <p className="para">
                Outsource the entire recruitment and work for managment process.
                Cost Effective, Flexible, Temporary/ Project based staffing.
              </p>
            </div>

            <div className="service service2">
              <i className="ti-light-bulb"></i>
              <h4>RPO Services</h4>
              <p className="para">
                Assisting in Recruitment Process. End to End Recruitment.
                Customized Solutitions Cost Efficiency Scalability
              </p>
            </div>

            <div className="service service3">
              <i className="ti-money"></i>
              <h4>Permanent Staffing</h4>
              <p className="para">
                Facilitating permanent hires through client collaboration,
                screening, evaluation, and leveraging market insights.
              </p>
            </div>
            <div className="service service3">
              <i className="ti-money"></i>
              <h4>Staff augmentation</h4>
              <p className="para">
                Efficiently meet staffing demands with cost-effective,
                specialized, and legally compliant project-based professionals.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Services;
