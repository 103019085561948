import React from "react";
import "./index.css";
import company1 from "../../Assets/Images/Varuna-bg.jpg";
import company2 from "../../Assets/Images/tattvam.png";
import company3 from "../../Assets/Images/ritco.png";
import company4 from "../../Assets/Images/online.png";
import company5 from "../../Assets/Images/intellect.png";
import company6 from "../../Assets/Images/Norspin.png";
import company7 from "../../Assets/Images/canoe.png";

const Vision = () => {
  return (
    <div className="vision_cont">
      <div className="vision_body">
        <div className="vision_head">
          <p>Our Prestige-Our Clients</p>
        </div>
        <div className="vision_text">
          <div className="companys service1">
            <img src={company1} alt="" />
          </div>
          <div className="companys service1">
            <img src={company2} alt="" />
          </div>
          <div className="companys service1">
            <img src={company3} alt="" />
          </div>
          <div className="companys service1">
            <img src={company4} alt="" />
          </div>
          <div className="companys service1">
            <img src={company5} alt="" />
          </div>
          <div className="companys service1">
            <img src={company6} alt="" style={{ width: "12vw" }} />
          </div>
          <div className="companys service1 ">
            <img src={company7} alt="" className="canoe" />
          </div>
          <div className="companys service1 no-icon">
            <h4 className="">Gawar Constructions Ltd</h4>
          </div>
          <div className="companys service1 no-icon">
            <h4 className="">Himalayan Infracom Pvt Ltd</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vision;
