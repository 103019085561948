// import "./index.css";

// import React from "react";
// import banner from "../../Assets/Images/ben-rosett-WdJkXFQ4VHY-unsplash.jpg";

// const About = () => {
//   return (
//     <div className="abt_container">
//       <div className="abt_cont">
//         <div className="abt_body">
//           <div className="abt_heading font-glacial-bold">
//             <p>ABOUT US</p>
//           </div>
//           <div className="abt_subheading">
//             <p>
//               At Sahitya Reruiters LLP , we are dedicated to connecting talent
//               with opportunity. With a commitment to excellence and a passion
//               for helping businesses thrive and individuals succeed, we have
//               established ourselves as a trusted partner in the world of
//               recruitment and placement.
//             </p>
//           </div>
//         </div>

//         <img className="abt_img" src={banner} alt="" />
//       </div>
//     </div>
//   );
// };

// export default About;

import React, { useState } from "react";
import valImg from "../../Assets/Images/ben-rosett-WdJkXFQ4VHY-unsplash.jpg";
import "./index.css";

const About = () => {
  const [activeButton, setActiveButton] = useState("Excellence");

  const handleButtonClick = (buttonLabel) => {
    setActiveButton(buttonLabel);
  };
  return (
    <div className="value-cont" id="about">
      <div className="value-body">
        <div className="btntext">
          <div className="valtitle">
            <h1>About Us</h1>
          </div>

          {/* <div className="button-container">
            <button
              className={activeButton === "Excellence" ? "active" : ""}
              onClick={() => handleButtonClick("Excellence")}
            >
              Expertise
            </button>
            <button
              className={activeButton === "Innovation" ? "active" : ""}
              onClick={() => handleButtonClick("Innovation")}
            >
              Personalized Approach
            </button>
            <button
              className={activeButton === "Client-Centric" ? "active" : ""}
              onClick={() => handleButtonClick("Client-Centric")}
            >
              Cutting-Edge Technology
            </button>
            <button
              className={activeButton === "Continuous Learning" ? "active" : ""}
              onClick={() => handleButtonClick("Continuous Learning")}
            >
              Integrity and Transparency
            </button>
          </div> */}
          <div className="text-container">
            <p>
              At Sahitya Reruiters LLP , we are dedicated to connecting talent
              with opportunity. With a commitment to excellence and a passion
              for helping businesses thrive and individuals succeed, we have
              established ourselves as a trusted partner in the world of
              recruitment and placement.
            </p>
          </div>
        </div>
        <div className="value-img">
          <img src={valImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default About;
