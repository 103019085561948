import About from "./Components/about/About";
import Contact from "./Components/contact/contact";
import Home from "./Components/home/Home";
import Navbar from "./Components/navBar/NavBar";
import Recruitment from "./Components/recruitment/Recruitment";
import Services from "./Components/services/Services";
import Testimonial from "./Components/testimonial/testimonial";
import Values from "./Components/values/Values";
import Vision from "./Components/vision/Vision";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Home />
      <About />
      <Services />
      <Vision />
      <Testimonial />
      <Values />
      <Recruitment />
      <Contact />
    </div>
  );
}

export default App;
