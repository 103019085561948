import "./index.css";

import React from "react";
// import banner1 from "../../Assets/Images/banneerImg1.jpg";

const Home = () => {
  return (
    <div className="home_cont">
      <div className="home_body">
        <div className="home_heading">
          <p>SAHITYA RECRUITERS</p>
        </div>
        <div className="home_subheading">
          <p>Talent Matchmakers for the Future.</p>
        </div>
        <div className="home_btncont">
          <div className="home_hire_btn">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSeJH8ZUOgiYIJ7k3TmUGOvdI7wIaPuShQWSQIHkNW9qtEdOEw/viewform?usp=sf_link"
              className="hire_link"
            >
              Hire now
            </a>
          </div>
          <div className="home_apply_btn">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSezq4DHpauAH9bWfztzl-w2f1iBMU8qxmMy96OEXfgjtloYcQ/viewform?usp=sf_link"
              className="apply_link"
            >
              Apply now
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
