import React, { useState } from "react";
import valImg from "../../Assets/Images/christin-hume-mfB1B1s4sMc-unsplash.jpg";
import "./index.css";

const Values = () => {
  const [activeButton, setActiveButton] = useState("Excellence");

  const handleButtonClick = (buttonLabel) => {
    setActiveButton(buttonLabel);
  };
  return (
    <div className="value-cont">
      <div className="value-body">
        <div className="btntext">
          <div className="valtitle font-glacial-bold">
            <h1>What Sets Us Apart?</h1>
          </div>

          <div className="button-container-values">
            <button
              className={
                activeButton === "Excellence" ? "active" : "btn-values"
              }
              onClick={() => handleButtonClick("Excellence")}
            >
              Expertise
            </button>
            <button
              className={
                activeButton === "Innovation" ? "active" : "btn-values"
              }
              onClick={() => handleButtonClick("Innovation")}
            >
              Personalized Approach
            </button>
            <button
              className={
                activeButton === "Client-Centric" ? "active" : "btn-values"
              }
              onClick={() => handleButtonClick("Client-Centric")}
            >
              Cutting-Edge Technology
            </button>
            <button
              className={
                activeButton === "Continuous Learning" ? "active" : "btn-values"
              }
              onClick={() => handleButtonClick("Continuous Learning")}
            >
              Integrity and Transparency
            </button>
          </div>
          <div className="text-container">
            {activeButton === "Excellence" && (
              <p className="fade-in">
                Our team comprises experienced professionals with in-depth
                industry knowledge. We understand the unique challenges of
                various sectors and leverage this expertise to find the perfect
                match for both employers and candidates.
              </p>
            )}
            {activeButton === "Innovation" && (
              <p className="fade-in">
                We don't believe in one-size-fits-all solutions. Every company
                and every candidate is unique. That's why we take a personalized
                approach, tailoring our services to meet the specific needs and
                goals of our clients.
              </p>
            )}
            {activeButton === "Client-Centric" && (
              <p className="fade-in">
                We harness the power of technology to streamline the placement
                process, making it faster and more efficient. Our advanced tools
                and data-driven insights ensure we make data-informed decisions.
              </p>
            )}
            {activeButton === "Continuous Learning" && (
              <p className="fade-in">
                Honesty and transparency are the cornerstones of our business.
                We maintain open lines of communication with both clients and
                candidates, ensuring that everyone involved is informed and
                confident throughout the placement journey.
              </p>
            )}
          </div>
        </div>

        <div className="value-img">
          <img src={valImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Values;
