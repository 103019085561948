import React, { useContext, useRef, useState } from "react";
import "./index.css";
import phone from "../../Assets/Images/svg/phone.svg";
import mail from "../../Assets/Images/svg/mail.svg";
import location from "../../Assets/Images/svg/location.svg";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const formRef = useRef();
  const [done, setDone] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_sigxn65",
        "template_3do0s23",
        formRef.current,
        "user_I8sMcd7PCvoA7Dpj3c5td"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div className="c" id="contact">
      <div className="c-bg"></div>
      <div className="c-wrapper">
        <div className="c-left">
          <h1 className="c-tittle">Get In Touch</h1>
          <div className="c-info">
            <div className="c-info-item">
              <img src={phone} alt="" className="c-icon" />
              <div className="info-box">
                <h2>Phone Number</h2>
                <p>9034846632,9643443262</p>
              </div>
            </div>
            <div className="c-info-item">
              <img src={mail} alt="" className="c-icon" />
              <div className="info-box">
                <h2>Email Address </h2>
                <p>sahityarecruiters@gmail.com</p>
              </div>
            </div>
            <div className="c-info-item">
              <img src={location} alt="" className="c-icon" />
              <div className="info-box">
                <h2>Address </h2>
                <p>Building No.122 Block Q1 South City 2</p>
                <p>Block Q1 South City 2</p>
                <p>Gurugram, 122018</p>
              </div>
            </div>
          </div>
        </div>
        <div className="c-right">
          <p className="c-desc">
            <b>What's your need?</b> To get something done take the first step
            and contact through the form below.
          </p>
          <form ref={formRef} onSubmit={handleSubmit}>
            <input type="text" placeholder="Name" name="user_name" />
            <input type="text" placeholder="Subject" name="user_subject" />
            <input type="text" placeholder="Email" name="user_email" />
            <textarea name="message" rows="5" placeholder="Message"></textarea>
            <button className="submit">Submit</button>
            {done &&
              "Thank you for the response! will get back to you shortly!"}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
