import "./index.css";
import React, { useState } from "react";

const Recruitment = () => {
  return (
    <div className="recruitment-body">
      <div id="wrapper">
        <div className="recuitment-container">
          <h1 className="font-glacial-bold">Streamlined Process Flow</h1>

          <ol className="process-chart">
            <li>
              <div>
                <p>Selection Criteria</p>
              </div>
            </li>
            <li>
              <div>
                <p>Job Profiles</p>
              </div>
            </li>
            <li>
              <div>
                <p>Search for Suitable Candidates</p>
              </div>
            </li>
            <li>
              <div>
                <p>Screening</p>
              </div>
            </li>
            <li>
              <div>
                <p>
                  Online Codiity Test From Level 1 , Level 2 and HR Interview
                </p>
              </div>
            </li>
            <li>
              <div>
                <p>Shortlist </p>
              </div>
            </li>
            <li>
              <div>
                <p>Functional Interview </p>
              </div>
            </li>
            <li>
              <div>
                <p>Employment</p>
              </div>
            </li>
          </ol>
        </div>
        <div className="content">
          <div className="title font-glacial-bold">
            <p>RECRUITMENT PROCESS</p>
          </div>
          <div className="recuitment-para">
            <p>
              At Sahitya Group, we take great pride in our meticulous and
              comprehensive recruitment process. Our commitment to selecting the
              best candidates for our team is reflected in the eight-step
              approach we employ, ensuring we onboard individuals who not only
              meet the requirements but also embody our values and culture.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recruitment;
