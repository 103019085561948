import React from "react";
import "./index.css";
// import 'swiper/swiper.min.css';

import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const employers = [
  {
    test: "Kushagrah is fast and professional.. we got good candidates.",
    name: "Vaibhav",
  },
  { test: "Nice team, they screen candidates well. ", name: "Tushar" },
  {
    test: "The best thing about them is they screen candidates well and they understood our requirement well. ",
    name: "Sonam",
  },
  {
    test: "Working with them was good. We got the candidates fast. They have a good database. ",
    name: "Shreya",
  },
];
const employee = [
  {
    test: "The best thing about Sahitya Recruiters is that it is run by a Chartered Accountant and he knows where the candidate will fix. ",
    name: "CA Komal Goyal",
  },
  {
    test: "Their follow up is good, i am satisfied with the services.",
    name: "Mohit",
  },
  {
    test: "Nice initiative, best thing is their follow up and smooth onboarding.",
    name: "CA Mishti Arora",
  },
  {
    test: "They understand the candidates requirement very well and helps to find the best job. They are fast.",
    name: "Ambuj",
  },
  {
    test: "They helped me to find a job in 10 days with smooth onboarding. I am satisfied.",
    name: "Sakshi",
  },
];

const Testimonial = () => {
  return (
    <div className="test-cont">
      <div className="testcont-title">
        <p className="cont-title">Testimonitals</p>
        <p className="cont-subtitle">
          Real Stories, Real Results: Hear What Our Clients Have to Say
        </p>
      </div>
      <div className="test-body">
        <div className="section">
          <p className="section-title">Employers</p>
          <div className="section-cont">
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={50}
              slidesPerView={1}
              onSlideChange={() => console.log("slide changed")}
              onSwiper={(swiper) => console.log(swiper)}
              navigation
              style={{
                paddingLeft: "1.8vw",
              }}
            >
              {employers.map((test) => {
                return (
                  <SwiperSlide>
                    <div className="testimonail">
                      <div className="text">
                        <div className="quote">&#8220;</div>
                        <p className="review">{test.test}</p>
                      </div>
                      <div className="info" style={{}}>
                        <span className="name">{test.name}</span>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
        <div className="section">
          <p className="section-title">Job Seekers</p>
          <div className="section-cont">
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={50}
              slidesPerView={1}
              onSlideChange={() => console.log("slide changed")}
              onSwiper={(swiper) => console.log(swiper)}
              navigation
              style={{
                paddingLeft: "1.8vw",
              }}
            >
              {employee.map((test) => {
                return (
                  <SwiperSlide>
                    <div className="testimonail">
                      <div className="text">
                        <div className="quote">&#8220;</div>
                        <p className="review">{test.test}</p>
                      </div>
                      <div className="info">
                        <span className="name">{test.name}</span>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
