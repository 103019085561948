import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
// import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
// import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

import { Link } from "react-scroll";

import logo from "../../Assets/Sahitya-Logo.svg";
import Backdrop from "@mui/material/Backdrop";
import "./index.css";

const pages = ["About Us", "Jobseekaers", "Employers", "Contact Us"];
// const settings = ["Profile", "Account", "Dashboard", "Logout"];

function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  //   const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [activeMenuItem, setActiveMenuItem] = React.useState(pages[0]); // Set the default active menu item

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  //   const handleOpenUserMenu = (event) => {
  //     setAnchorElUser(event.currentTarget);
  //   };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  //   const handleCloseUserMenu = () => {
  //     setAnchorElUser(null);
  //   };

  const handleMenuItemClick = (page) => {
    setActiveMenuItem(page);
    handleCloseNavMenu();
  };

  return (
    <Backdrop
      sx={{
        height: "100px",
        color: "#14b53f",
        background:
          "linear-gradient(180deg, rgba(0,0,0,0.7441570378151261) 53%, rgba(255,255,255,0) 100%)",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open="true"
    >
      <AppBar
        position="static"
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          position: "fixed",
          top: 0,
          marginTop: -6,
          zIndex: 2,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters className="toolbar">
            <img
              src={logo}
              alt="Logo SVG"
              style={{ width: "200px", marginRight: "50px" }}
            />

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
              >
                {pages.map((page) => (
                  <Link
                    activeClass="active"
                    to="test1"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                  >
                    <Typography textAlign="center">{page}</Typography>
                  </Link>
                ))}
              </Menu>
            </Box>

            <Box
              sx={{
                // flexGrow: 1,
                display: { xs: "none", md: "flex", justifySelf: "flex-end" },
                gap: 10,
              }}
            >
              {/* {pages.map((page) => (
                <a
                  key={page}
                  onClick={() => handleMenuItemClick(page)}
                  sx={{
                    my: 2,
                    color: activeMenuItem === page ? "black" : "#667085",
                    display: "block",
                  }}
                >
                  {page}
                </a>
              ))} */}
              <Link
                to="about"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                // onClick={}
                className="menu-item"
                activeClass="none"
              >
                <Typography textAlign="center">About Us</Typography>
              </Link>
              {/* <Link
                to="https://docs.google.com/forms/d/e/1FAIpQLSezq4DHpauAH9bWfztzl-w2f1iBMU8qxmMy96OEXfgjtloYcQ/viewform?usp=sf_link"
                href="https://docs.google.com/forms/d/e/1FAIpQLSezq4DHpauAH9bWfztzl-w2f1iBMU8qxmMy96OEXfgjtloYcQ/viewform?usp=sf_link"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                className="menu-item"
                // onClick={}
              >
                <Typography textAlign="center">Jobseekaers</Typography>
              </Link> */}
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSezq4DHpauAH9bWfztzl-w2f1iBMU8qxmMy96OEXfgjtloYcQ/viewform?usp=sf_link"
                className="menu-link"
              >
                <Typography textAlign="center">Jobseekers</Typography>
              </a>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSeJH8ZUOgiYIJ7k3TmUGOvdI7wIaPuShQWSQIHkNW9qtEdOEw/viewform?usp=sf_link"
                className="menu-link"
              >
                <Typography textAlign="center">Employers</Typography>
              </a>

              <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                // onClick={}
                className="menu-item"
              >
                <Typography textAlign="center">Contact Us</Typography>
              </Link>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Backdrop>
  );
}

export default Navbar;
